<template>
    <b-row>
        <b-col cols="12">
            <div
                class="d-sm-flex justify-content-between align-items-center mb-4"
            >
                <feather
                    type="arrow-left-circle"
                    v-b-popover.hover.top="'Presione esc'"
                    role="button"
                    class="text-secondary"
                    size="2rem"
                    @click="back()"
                ></feather>
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back()"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
        >
            <b-card class="mb-4 w-100">
                <div>
                    <b-form class="p-5">
                        <div class="d-flex">
                            <div>
                                <h3 class="mb-2">Datos del rol</h3>
                                <div class="text-gray mb-5">
                                    Por favor registre los datos del rol para
                                    continuar
                                </div>
                            </div>
                        </div>
                        <b-row v-hotkey="keymap">
                            <b-col cols="12" class="mb-4">
                                <b-form-group>
                                    <label for="enabled">Habilitado</label>
                                    <b-form-checkbox
                                        id="enabled"
                                        size="lg"
                                        switch
                                        v-model="$v.rol.enabled.$model"
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="descripcion"
                                        >Descripción
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="descripcion"
                                        type="text"
                                        placeholder="Ej: Mi nuevo rol"
                                        @focus="onFocused"
                                        v-model="$v.rol.descripcion.$model"
                                        :state="validateState('descripcion')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.rol.descripcion.$anyError"
                                    >
                                        ({{ $v.rol.descripcion.$model.length }}
                                        /
                                        {{
                                            $v.rol.descripcion.$params.maxLength
                                                .max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.rol.descripcion.minLength"
                                    >
                                        Este campo debe tener al menos
                                        {{
                                            $v.rol.descripcion.$params.minLength
                                                .min
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.rol.descripcion.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col
                                cols="12"
                                sm="12"
                                md="4"
                                xl="4"
                                class="border p-4"
                                style="max-height: 400px; overflow-y: scroll"
                            >
                                <div class="font-weight-normal">
                                    <div
                                        v-for="(item, i) in getMenuComplete"
                                        :key="i"
                                    >
                                        <div v-if="item.header" class="pb-1">
                                            <feather
                                                v-if="
                                                    rol.authorities.find(
                                                        (m) =>
                                                            m.menu_id == item.id
                                                    ) !== undefined
                                                "
                                                type="check-circle"
                                                class="text-white mr-2 bg-secondary rounded-circle"
                                                size="1rem"
                                            ></feather>
                                            <feather
                                                v-else
                                                type="circle"
                                                role="button"
                                                class="text-secondary mr-2"
                                                size="1rem"
                                            ></feather>
                                            <span>{{ item.title }}</span>
                                        </div>
                                        <div v-else class="ml-2 pb-1">
                                            <div class="ml-2 pb-1">
                                                <feather
                                                    v-if="
                                                        rol.authorities.find(
                                                            (m) =>
                                                                m.menu_id ==
                                                                item.id
                                                        ) !== undefined
                                                    "
                                                    type="check-circle"
                                                    class="text-white mr-2 bg-secondary rounded-circle"
                                                    size="1rem"
                                                ></feather>
                                                <feather
                                                    v-else
                                                    type="circle"
                                                    role="button"
                                                    class="text-secondary mr-2"
                                                    size="1rem"
                                                ></feather>
                                                <span>
                                                    {{ item.title }}
                                                </span>
                                            </div>
                                            <div
                                                v-for="(
                                                    child, iChild
                                                ) in item.children"
                                                :key="iChild"
                                                class="ml-4 pb-1 d-flex flex-row align-items-center"
                                            >
                                                <feather
                                                    v-if="
                                                        rol.authorities.find(
                                                            (m) =>
                                                                m.menu_id ==
                                                                child.id
                                                        ) !== undefined &&
                                                        !rol.authorities.find(
                                                            (m) =>
                                                                m.menu_id ==
                                                                child.id
                                                        )?.disabled
                                                    "
                                                    type="check-circle"
                                                    role="button"
                                                    class="text-white mr-2 bg-primary rounded-circle"
                                                    size="1rem"
                                                    @click="
                                                        !read_only &&
                                                            showPermissions(
                                                                item,
                                                                child
                                                            )
                                                    "
                                                ></feather>
                                                <feather
                                                    v-else-if="
                                                        rol.authorities.find(
                                                            (m) =>
                                                                m.menu_id ==
                                                                child.id
                                                        ) !== undefined &&
                                                        rol.authorities.find(
                                                            (m) =>
                                                                m.menu_id ==
                                                                child.id
                                                        )?.disabled
                                                    "
                                                    type="check-circle"
                                                    class="text-white mr-2 bg-secondary rounded-circle"
                                                    size="1rem"
                                                ></feather>
                                                <feather
                                                    v-else
                                                    type="circle"
                                                    role="button"
                                                    class="text-secondary mr-2"
                                                    size="1rem"
                                                    @click="
                                                        !read_only &&
                                                            showPermissions(
                                                                item,
                                                                child
                                                            )
                                                    "
                                                ></feather>
                                                <span
                                                    v-if="
                                                        rol.authorities.find(
                                                            (m) =>
                                                                m.menu_id ==
                                                                child.id
                                                        )
                                                    "
                                                    role="button"
                                                    @click="show(child)"
                                                    :class="
                                                        currentItem?.id ===
                                                        child.id
                                                            ? 'font-weight-bolder'
                                                            : ''
                                                    "
                                                    >{{ child.title }}</span
                                                >
                                                <span v-else>{{
                                                    child.title
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                            <b-col
                                cols="12"
                                sm="12"
                                md="8"
                                xl="8"
                                class="border px-0"
                                style="max-height: 400px; overflow-y: scroll"
                            >
                                <div
                                    class="border-bottom pl-4 pt-2 pb-2 font-weight-bolder bg-light"
                                >
                                    Permisos
                                    <span v-if="currentItem">
                                        &#8594; {{ currentItem.title }}</span
                                    >
                                </div>
                                <div class="p-4" v-if="currentItem">
                                    <div
                                        v-for="(
                                            permiso, iPermiso
                                        ) in permisos.filter(
                                            (p) => p.menu_id == currentItem.id
                                        )"
                                        :key="iPermiso"
                                        class="pb-1"
                                    >
                                        <feather
                                            v-if="
                                                rol.authorities[
                                                    indexCurrentItem
                                                ].permisos_id.find(
                                                    (id) => id == permiso.id
                                                ) !== undefined &&
                                                !disabled_permisos.includes(
                                                    permiso.id
                                                )
                                            "
                                            type="check-circle"
                                            role="button"
                                            class="text-white mr-2 bg-primary rounded-circle"
                                            size="1rem"
                                            @click="
                                                !read_only &&
                                                    addPermission(permiso.id),
                                                    validateStatePermisos(
                                                        indexCurrentItem,
                                                        'permisos_id'
                                                    )
                                            "
                                        ></feather>
                                        <feather
                                            v-else-if="
                                                rol.authorities[
                                                    indexCurrentItem
                                                ].permisos_id.find(
                                                    (id) => id == permiso.id
                                                ) !== undefined &&
                                                disabled_permisos.includes(
                                                    permiso.id
                                                )
                                            "
                                            type="check-circle"
                                            class="text-white mr-2 bg-secondary rounded-circle"
                                            size="1rem"
                                        ></feather>
                                        <feather
                                            v-else
                                            type="circle"
                                            role="button"
                                            class="text-secondary mr-2"
                                            size="1rem"
                                            @click="
                                                !read_only &&
                                                    addPermission(permiso.id),
                                                    validateStatePermisos(
                                                        indexCurrentItem,
                                                        'permisos_id'
                                                    )
                                            "
                                        ></feather>
                                        <span>{{ permiso.descripcion }}</span>
                                    </div>
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            !$v.rol.authorities.$each[
                                                indexCurrentItem
                                            ].permisos_id.required
                                        "
                                    >
                                        Se requiere agregar al menos un permiso
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="float-right mt-4">
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back()"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        class="d-flex align-items-center"
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import {
    required,
    minLength,
    requiredIf,
    maxLength
} from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import {
    onFocused,
    compareObjects,
    addListenerCommands,
    removeListenerCommands,
    goToNextField
} from '@/utils/others'

const { API } = services

const defaultRol = () =>
    JSON.parse(
        JSON.stringify({
            enabled: true,
            descripcion: '',
            tipo: 'NORMAL',
            empresa_id: null,
            authorities: [
                {
                    menu_id: 1,
                    permisos_id: [],
                    disabled: true
                },
                {
                    menu_id: 2,
                    permisos_id: [],
                    disabled: true
                },
                {
                    menu_id: 3,
                    permisos_id: [4, 5, 6],
                    disabled: true
                },
                {
                    menu_id: 4,
                    permisos_id: [15, 16, 17],
                    disabled: true
                },
                {
                    menu_id: 7,
                    permisos_id: [],
                    disabled: true
                },
                {
                    menu_id: 11,
                    permisos_id: [173],
                    disabled: true
                }
            ]
        })
    )

export default {
    name: 'CrearRol',
    mixins: [validationMixin],
    components: {},
    data: () => ({
        disabled_ids: [1, 2, 3, 5, 7, 11],
        disabled_permisos: [5, 6, 7, 16, 17, 18, 173],
        rol: defaultRol(),
        isLoading: false,
        isSaved: true,
        read_only: false,
        currentItem: null,
        indexCurrentItem: null,
        permisos: [],
        menu: [],
        rolCopy: defaultRol()
    }),
    watch: {
        rol: {
            handler(newValue) {
                this.isSaved = compareObjects(this.rolCopy, newValue)
            },
            deep: true
        }
    },
    mounted() {
        this.goToNextField('descripcion')
        // this.getPermisos()
        this.getParams()
        addListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    validations: {
        rol: {
            enabled: {},
            descripcion: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(150)
            },
            authorities: {
                required,
                minLength: minLength(1),
                $each: {
                    menu_id: {},
                    permisos_id: {
                        required: requiredIf(function (model) {
                            const required_validation =
                                model.hijo && !model.dependencia
                            // console.log(
                            //     this.menu.find((m) => m.id === model.menu_id),
                            //     model,
                            //     this.permisos,
                            //     model.hijo,
                            //     !model.dependencia,
                            //     required_validation
                            // )
                            return required_validation
                        })
                    }
                }
            }
        }
    },
    methods: {
        goToNextField,
        onFocused,
        getParams() {
            const { rol_id } = this.$route.params

            this.getCurrentRolById(rol_id)
            this.read_only = this.$route.meta.read_only
        },
        addPermission(permiso_id) {
            const index =
                this.rol.authorities[this.indexCurrentItem].permisos_id.indexOf(
                    permiso_id
                )
            if (index === -1) {
                this.rol.authorities[this.indexCurrentItem].permisos_id.push(
                    permiso_id
                )
            } else {
                this.rol.authorities[this.indexCurrentItem].permisos_id.splice(
                    index,
                    1
                )
            }
        },
        addAuthority(menu_id) {
            const obj = this.rol.authorities.find((p) => p.menu_id === menu_id)
            if (obj) {
                const index = this.rol.authorities.indexOf(obj)
                this.rol.authorities.splice(index, 1)
                return { action: 'DELETED' }
            } else {
                const permisos_id = this.permisos
                    .filter((p) => p.menu_id == menu_id)
                    .map((p) => p.id)

                // console.log(menu_id, permisos_id)

                const menu_item = this.menu.find((i) => i.id == menu_id)

                const defaultAuthority = {
                    menu_id,
                    permisos_id,
                    disabled: this.disabled_ids.includes(menu_id),
                    hijo: !menu_item.padre,
                    dependencia: menu_item.dependencia
                }

                this.rol.authorities.push(defaultAuthority)
                return {
                    action: 'INSERTED',
                    lastIndex: this.rol.authorities.length - 1
                }
            }
        },
        addParent(parent) {
            const obj = this.rol.authorities.find(
                (p) => p.menu_id === Number(parent.id)
            )
            if (!obj) {
                return this.addAuthority(Number(parent.id))
                // if (parent.hijo !== undefined && parent.hijo !== null) {
                // console.log(Number(parent.hijo))
                // const gParent = this.menu.find((p) => p.id == parent.hijo)
                // this.addAuthority(Number(parent.hijo))
                // }
            }

            if (obj) {
                const children = []
                const children_ids = this.menu
                    .filter((m) => m.hijo == parent.id)
                    .map((c) => c.id)
                // console.log(children_ids)
                children_ids.forEach((id) => {
                    const retrieveAuthority = this.rol.authorities.find(
                        (m) => m.menu_id == id
                    )
                    if (retrieveAuthority) {
                        children.push(retrieveAuthority)
                    }
                })

                if (children.length === 0) {
                    const index = this.rol.authorities.indexOf(obj)
                    this.rol.authorities.splice(index, 1)
                }
                return
                // if (parent.hijo !== undefined && parent.hijo !== null) {
                // const gParent = this.menu.find((p) => p.id == parent.hijo)
                // this.addParent(gParent)
                // console.log(parent)
                // }
            }
        },
        show(child) {
            const obj = this.rol.authorities.find((m) => m.menu_id == child.id)
            const index = this.rol.authorities.indexOf(obj)
            this.currentItem = JSON.parse(JSON.stringify(child))
            this.indexCurrentItem = index
        },
        showPermissions(parent, child) {
            const { action, lastIndex } = this.addAuthority(Number(child.id))
            if (action === 'INSERTED') {
                this.currentItem = JSON.parse(JSON.stringify(child))
                this.indexCurrentItem = lastIndex
            } else {
                this.currentItem = null
                this.indexCurrentItem = null
            }
            this.addParent(parent)
            if (parent.hijo !== undefined && parent.hijo !== null) {
                const gParent = this.menu.find((p) => p.id == parent.hijo)
                this.addParent(gParent)
            }
        },
        getCurrentRolById(rol_to_edit_id) {
            this.getUltimaSede
            const roles = JSON.parse(JSON.stringify(this.getUserData.roles))
            const currentRole =
                roles.length === 1 && roles[0].sede_id === 0
                    ? roles[0].role
                    : roles.find((r) => r.sede_id === this.getUltimaSede.id)

            API.POST({
                url: 'seguridad/role/query',
                data: {
                    p_datajson: {
                        id: currentRole.id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.permisos = [...dato.permisos]
                        // console.log(this.permisos.find((p) => p.id == 205))
                        this.menu = [...dato.menus]
                        // console.log(this.menu)
                        if (rol_to_edit_id) {
                            this.getRolById(rol_to_edit_id)
                        }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getRolById(id) {
            // console.log('acá 2');
            API.POST({
                url: 'seguridad/role/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        const rol = { ...dato }
                        this.rolCopy = { ...dato }
                        // console.log(rol.authorities)
                        rol.authorities.forEach((authority) => {
                            if (this.disabled_ids.includes(authority.menu_id)) {
                                authority.disabled = true
                            }

                            const menu_item = this.menu.find(
                                (i) => i.id == authority.menu_id
                            )

                            // console.log(authority.menu_id, 'menu_id')

                            authority.hijo = !menu_item.padre
                            authority.dependencia = menu_item.dependencia
                        })

                        this.rol = { ...rol }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.rol.$invalid

            // console.log(valid, this.$v.rol)

            if (valid) {
                this.isLoading = true
                this.rol.empresa_id = this.getUltimaEmpresa.id
                const rol = JSON.parse(JSON.stringify(this.rol))
                const p_opc = this.rol.id ? 'UPDATE' : 'INSERT'

                API.POST({
                    url: 'seguridad/role/crud',
                    data: {
                        p_datajson: {
                            ...rol
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            this.back()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.rol.$touch()
            }
        },
        back() {
            this.$router.back()
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.rol[key]
            return $dirty ? !$error : null
        },
        validateStatePermisos(index, key) {
            // console.log(index, key, this.$v.rol.authorities.$each[index])
            const { $dirty, $error } = this.$v.rol.authorities.$each[index][key]
            return $dirty ? !$error : null
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        }
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 27 }
            return json
        },
        ...mapGetters('usuario', [
            'getUserData',
            'getAuditoriaJson',
            'getMenuComplete'
        ]),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapGetters('sedes', ['getUltimaSede'])
        // ...mapState('usuario', ['menu'])
    }
}
</script>

<style></style>
